import "./about.css"

function About() {
    return <>
        <div className="about_header">
            &nbsp;
        </div>
        <div className="about_content">
                <p>
                    I can’t even tell you any story about how I started taking pictures at an early ages, nor a young
                    talent was I. I bought my first camera in the second half of my life and was amazed by freezing the
                    time with this small device. It’s not only about documenting the life flowing around us but finding
                    a fitting composition. The drama we seek is just there, but the photographer’s eye catches the
                    moment. Together with the fast feedback loop, I fell in love with the new hobby, photography.
                </p>
                <p>
                    Recently, I was thinking about my digital photography archive that I built on hard disks with time.
                    The number of photos was to that extent I couldn’t even view them all in my lifetime. It led me to
                    think through the options, either preserve them until my backup rots, other make something
                    meaningful out of it. The walls at home are full of pictures, and we prepare gifts for our family
                    and friends. But, still, there are too many of them. So, I decided to create this personal blog
                    page, AFK, about our journey for family and friends.
                </p>
                <p>
                    I was born in 1981 in Izmir, the city that lies on the most west coast of Turkey. After a still
                    childhood, I was interested in computer programming in my early high school days, which I took up a
                    hobby but became my profession later. I am also writing about tech stuff on bitwise.blog, another
                    non-commercial blog site.
                </p>
                <p>
                    If you want to contact me, please feel free to pick one of the links on the left hand side, or send
                    an e-mail to erhan ‘that sign‘ bagdemir ‘dot’ com.
                </p>

                <p>If you like my work feel free to follow me on <a
                    href="https://twitter.com/notingolmo">Twitter</a>, <a
                    href="https://facebook.com/bagdemir">Facebook</a> or <a
                    href="https://instagram.com/notingolmo">Instagram</a>.
                </p>
            </div>
        </>
}

export default About