import React from 'react'
import './gallery.css'
import './flexbin.css'
import { NavLink } from "react-router-dom";

class Gallery extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const photos = this.readPhotos().map(function (photo, index) {
            return <NavLink className="nav-link" to={"/photobook?i="+ index}>
                <img className="photo" src={"/photos/" + photo } />
            </NavLink>
        })
        return <div className="flexbin flexbin-margin">{ photos }</div>
    }

    readPhotos() {
        let images = [];
        let r = require.context('../../public/photos', false, /\.(png|jpe?g|svg)$/);
        r.keys().forEach((item, index) => {
            images.push(item.replace('./', ''))
        });
        return images;
    }
}

export default Gallery