import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
    return <>
         <div className="header">
             <div className="logo"><NavLink className="nav-link" to="/"><img src="logo.png" className="logo"/></NavLink></div>
             <div className="nav">
                 <li className="nav_item"><NavLink className="nav-link" to="/about">About</NavLink></li>
                 <li className="nav_item"><NavLink className="nav-link" to="/street">Street</NavLink></li>
                 <li className="nav_item"><NavLink className="nav-link" to="/landscape">Landscape</NavLink></li>
                 <li className="nav_item"><NavLink className="nav-link" to="/photobook">Travel</NavLink></li>
                 <li className="nav_item"><NavLink className="nav-link" to="/">Home</NavLink></li>
             </div>
         </div>
     </>
}

export default Navigation