import {NavLink} from "react-router-dom";
import React from "react";

function Footer() {
    return <>
        <div className="footer">
            <br/><br/>
            2023 Copyright Erhan Bagdemir Photography | <NavLink className="nav-link" to="/imprint">Imprint</NavLink>
        </div>
    </>
}

export default Footer