import "./photobook.css"
import React from "react";
import {useSearchParams} from "react-router-dom";
import {useState} from "react";

function readPhotos() {
    let images = [];
    let r = require.context('../../public/photos', false, /\.(png|jpe?g|svg)$/);
    r.keys().forEach((item, index) => {
        images.push(item.replace('./', ''))
    });
    return images;
}

function Photobook() {
    const [searchParams, setSearchParams] = useSearchParams()
    const photoList = readPhotos()
    const [state, setState] = useState({ i: Number(searchParams.get("i")) ? Number(searchParams.get("i")) : 0});
    const getPhoto = i => "/photos/" + photoList[state.i]
    const handleRightClick = e => {
        const next = state.i < photoList.length-1 ? state.i+1:state.i=0
        setState({ i: next})
    }
    const handleLeftClick = e => {
        setState({ i: state.i > 0 ? state.i - 1 : photoList.length-1})
    }
    return (
        <div className="photobook">
            <div className="left_arrow"><img src="arrow_left.png" onClick={handleLeftClick}/></div>
            <div className="photo_container"><img src={getPhoto(state.i)} className="photograph"/></div>
            <div className="arrow_container">
                <div className="arrow_container_left_arrow"><img src="arrow_left.png" onClick={handleLeftClick} className="imgarrow"/></div>
                <div className="arrow_container_right_arrow"><img src="arrow_right.png" onClick={handleRightClick} className="imgarrow"/></div>
            </div>
            <div className="right_arrow"><img src="arrow_right.png" onClick={handleRightClick}/></div>
        </div>)
}

export default Photobook