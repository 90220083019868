import "./about.css"

function Imprint() {
    return <>
        <div className="about_header">
            &nbsp;
        </div>
        <div className="about_content">
            <h1>Impressum</h1>
            <h2 id="m46">Diensteanbieter</h2>
            <p>Erhan Bagdemir</p>
            <p>Feenstieg 16</p>
            <p>25436 Tornesch</p>
            <p>Germany</p>
            <h2 id="m56">Kontaktmöglichkeiten</h2>E-Mail-Adresse: <p><a
            href="mailto:erhan.bagdemir@gmail.com">erhan.bagdemir@gmail.com</a></p>
            Telefon: <p>+491607505610</p>
            <p className="seal"><a href="https://datenschutz-generator.de/"
                                   title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                                   target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem
                Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
        </div>
    </>
}

export default Imprint